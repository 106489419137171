import settings from './settings';

const post = (url, val, image = null) => {
    let formData = new FormData();

    for (let d in val) {
        formData.append(d, val[d]);
    }

    if (image) {
        formData.append('image', image);
    }

    return fetch(settings.PHP_API_WEB_ROOT + url, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {

            if (result.error) {
                alert(result.message);
                return [result.error, null];
            }
            return [
                null, result.data
            ];
        });
};

const put = (url, val, image = null) => {
    let formData = new FormData();

    for (let d in val) {
        formData.append(d, val[d]);
    }
    formData.append('_method', 'PUT');

    if (image) {
        formData.append('image', image);
    }

    return fetch(settings.PHP_API_WEB_ROOT + url, {
        method: 'POST',
        body: formData
    })
        .then(res => res.json())
        .then(result => {
            if (result.error) {
                alert(result.message);
                return [result.error, null];
            }
            return [
                null, result.data
            ];
        });
};


const get = url => {
    return fetch(settings.PHP_API_WEB_ROOT + url)
        .then(res => res.json())
        .then(result => {
            if (result.error) {
                alert(result.message);
                return [result.error, null];
            }
            return [
                null, result.data
            ];
        });
};

export {
    get, post, put
}