import state from './store';
import {get, post, put } from './tools';


const productActions = {
    moveUp: ({detail}) => {
        productActions.move(detail.id, 'moveUp');
    },

    moveDown: ({detail}) => {
        productActions.move(detail.id, 'moveDown');
    },

    move: async (id, dir) => {
        state.setLoading(true);
        const [err, data] = await get(`product/${id}/${dir}`);

        state.setLoading(false);
        if (err) {
            alert(err);
            return;
        }

        state.update(s => {
            s.products = data;
            return s;
        });
    },
    save: async ({detail}) => {
        const {product, image} = detail;
        let err, data;

        if (product.id) {
            [err, data] = await put(`product/${product.id}`, product, image);
        } else {
            [err, data] = await post(`product`, product, image);
        }
        state.setLoading(false);

        if (err) {
            alert(err);
            return;
        }

        state.update(s => {
            s.products = data;
            s.page = 'ProductList';
            return s;
        });
        state.fixCatsMap();
    }
};

export default productActions;