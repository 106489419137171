import {writable} from 'svelte/store';

const state = writable({
    page: '',
    loading: true,
    categories:[],
    products:[],
});

state.fixCatsMap = () => {
    state.update(st => {
        st.catsMap = {};
        st.products.map(food => {
            const catid = food.food_category_id;
            let c = Number(st.catsMap[catid]) || 0;
            c++;
            st.catsMap[catid] = c;
        });
        return st;
    });
};

state.setLoading = val => {
    state.setKey('loading', val);
};

state.setKey = (key, value) => {
    state.update(st => {
        st[key] = value;
        return st;
    });
};


export default state;

