<script>
    import Link from './Link.svelte';
    import { createEventDispatcher } from 'svelte/internal'

    const dispatch = createEventDispatcher();


    export let category;
    export let catsmap = {};

    const mvup = async () => {
        dispatch('moveup', category);
    };
    const mvdn = async () => {
        dispatch('movedown', category);
    };
    const editCategory = () => {
        dispatch('edit', category);
    };
    const listProducts = () => {
        dispatch('select', category);
    };
    const addProduct = () => {
        dispatch('add', category);
    };
</script>

<tr>
    <td>
        <Link on:click="{ mvup }" css_class="move-button" title="Μετακίνηση πάνω"><i class="fa fa-arrow-up"></i></Link>
        <Link on:click="{ mvdn }" css_class="move-button" title="Μετακίνηση κάτω"><i class="fa fa-arrow-down"></i></Link>
    </td>
    <td>
        <Link css_class="link" on:click="{ editCategory }">{ category.title }</Link>
    </td>
    <td>
        <Link css_class="link" on:click="{ listProducts }">{ (catsmap[category.id] || 'Χωρίς') + ' προϊόντα' }</Link>
    </td>
    <td>
        { category.is_published > 0 ? 'Ορατή' : ''}
        <div class="controls">
            <a href="#" title="Προσθήκη προϊόντος στην κατηγορία" on:click="{ addProduct }"><i class="fa fa-plus"></i></a>
        </div>
    </td>
</tr>
