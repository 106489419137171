<script>
    import {createEventDispatcher} from 'svelte/internal'

    export let category = null;
    if (category === null) {
        category = {
            id: 0,
            zindex: 0,
            title: '',
            is_published: 1
        }
    }
    category.is_published = Number(category.is_published);

    const dispatch = createEventDispatcher();
    let isValid = false;

    const save = async () => {
        dispatch('save', category);
    };
    const cancel = () => {
        dispatch('cancel', category);
    };

    $: isValid = (category.title.length > 2);

</script>

<div class="panel panel-default">
    <div class="panel-heading">
        {#if category.id == 0 }
            <h2>Νέα κατηγορία</h2>
        {/if}
        {#if category.id > 0 }
            <h2>Επεξεργασία κατηγορίας</h2>
        {/if}
    </div>
    <div class="panel-body">
        <div class="form-group">
            <label>Τίτλος</label>
            <input type="text" class="form-control" bind:value="{ category.title }">
        </div>

        <div class="form-group">
            <div class="checkbox">
                <label>
                    <input type="checkbox" value="1" bind:checked="{ category.is_published }">
                    Ορατή στο μενού
                </label>
            </div>
        </div>
        <hr>
        <div class="text-right">
            <button class="btn btn-primary" disabled="{ !isValid }" on:click="{ save }">Ενημέρωση</button>
            <button class="btn btn-default" on:click="{ cancel }">Ακύρωση</button>
        </div>
    </div>
</div>
