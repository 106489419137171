import state from './store';
import {get, post, put } from './tools';

const updateStateCategories = data => state.update(s => {
    s.categories = data;
    s.page = 'CategoryList';
    return s;
});

const categoryActions = {
    moveUp: ({detail}) => {
        categoryActions.move(detail.id, 'moveUp');
    },
    moveDown: ({detail}) => {
        categoryActions.move(detail.id, 'moveDown');
    },
    move: async (id, dir) => {
        state.setLoading(true);
        const [err, data] = await get(`category/${id}/${dir}`);

        state.setLoading(false);
        if (err) {
            return;
        }

        updateStateCategories(data);
    },
    save: async ({detail}) => {
        state.setLoading(true);
        let err, data;

        if (detail.id) {
            [err, data] = await put(`category/${detail.id}`, detail);
        } else {
            [err, data] = await post(`category`, detail);
        }
        state.setLoading(false);

        if (err) {
            return;
        }
        updateStateCategories(data);

    }
};


export default categoryActions;