<script>
    import {createEventDispatcher} from 'svelte/internal'

    export let zindex = 0;
    export let categories = [];
    export let category = {
        id: null
    };
    export let product ;

    if (product == null) {
        product = {
            id: 0,
            food_category_id: (category.id || null),
            title: '',
            description: '',
            allergy: '',
            price: 0,
            zindex: 0,
            is_published: 1,
            options: '',
            ingredients: '',
        };
    }

    if (zindex) {
        product.zindex = zindex;
    }
    product.is_published = Number(product.is_published);

    const save = () => {
        const image = (files && files.length) ? files[0] : null;
        if (!product.id && !image) {
            alert('Please choose an image');
            return;
        }
        dispatch('save', {product, image});
    };

    const cancel = () => {
        dispatch('cancel', null);
    };


    const dispatch = createEventDispatcher();
    let isValid = false;
    let files = null;


    $: isValid = (product.title.length > 2);

</script>

<div class="panel panel-default">
    <div class="panel-heading">
        {#if product.id == 0 }
            <h2>Νέο προϊόν</h2>
        {/if}
        {#if product.id > 0 }
            <h2>Επεξεργασία προϊόντος</h2>
        {/if}
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Κατηγορία</label>
                    <select class="form-control" bind:value="{ product.food_category_id }">
                        {#each categories as cat }
                            <option value="{ cat.id }">{ cat.title }</option>
                        {/each}
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Τίτλος</label>
                    <input type="text" class="form-control" bind:value="{ product.title }">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Περιγραφή</label>
                    <textarea class="form-control" bind:value="{ product.description }"></textarea>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Επιλογές (μία ανά σειρά)</label>
                    <textarea class="form-control" bind:value="{ product.options }"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Συστατικά (ένα ανά σειρά)</label>
                    <textarea class="form-control" bind:value="{ product.ingredients }"></textarea>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Αλλεργίες</label>
                    <textarea class="form-control" bind:value="{ product.allergy }"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Τιμή</label>
                    <input type="number" class="form-control" bind:value="{ product.price }">
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" value="1" bind:checked="{ product.is_published }"> Ορατό στον κατάλογο
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Εικόνα</label>
                    <input class="form-control" type="file" bind:files>
                    {#if (product.id)}
                        <div class="product-image">
                            <img src="/menu_images/{ product.id }.jpg" alt="exisgting image">
                        </div>
                    {/if}
                </div>
            </div>
        </div>
        <hr>
        <div class="text-right">
            <button class="btn btn-primary" disabled="{ !isValid }" on:click="{ save }">Ενημέρωση</button>
            <button class="btn btn-default" on:click="{ cancel }">Ακύρωση</button>
        </div>
    </div>
</div>
