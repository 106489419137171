<script>
    import Link from './Link.svelte';
    import { createEventDispatcher } from 'svelte/internal'

    const dispatch = createEventDispatcher();


    export let product;

    const mvup = async () => {
        dispatch('moveup', product);
    };
    const mvdn = async () => {
        dispatch('movedown', product);
    };
    const edit = () => {
        dispatch('edit', product);
    };
    const add = () => {
        dispatch('add', product);
    };
</script>

<tr class="item">
    <td>
        <Link on:click="{ mvup }" css_class="move-button" title="Μετακίνηση πάνω"><i class="fa fa-arrow-up"></i></Link>
        <Link on:click="{ mvdn }" css_class="move-button" title="Μετακίνηση κάτω"><i class="fa fa-arrow-down"></i></Link>
    </td>
    <td>
        <Link css_class="link" on:click="{ edit }">{ product.title }</Link>
    </td>
    <td class="text-right">
        { product.price }
    </td>
    <td>
        { product.is_published > 0 ? 'Ορατό' : ''}
    </td>
</tr>