import App from './src/App.svelte';

function run_menu_admin() {
const app = new App({
        target: document.getElementById('foods-admin')
        //target: document.body
        // props: {}
});
return app;
}
export default run_menu_admin;
