<script>
    import state from './lib/store';
    import {get} from './lib/tools';
    import categoryActions from './lib/categoryActions';
    import productActions from './lib/productActions';

    import CategoryListItem from './CategoryListItem.svelte'
    import CategoryEditor from './CategoryEditor.svelte'
    import ProductListItem from './ProductListItem.svelte'
    import ProductEditor from './ProductEditor.svelte'
    import Link from './Link.svelte';

    let page;
    let loading;

    let selectedProduct = null;
    let selectedCategory = null;
    let selectedCategoryProducts = [];
    //
    // console.log('--');
    // console.log($state);
    // console.log('--');


    const showCategoryProducts = (event = null) => {
        selectedCategory = event.detail ? event.detail : selectedCategory;
        dd (event)
        if (event && event.detail) {
            selectedCategoryProducts = $state.products.filter(p => p.food_category_id == event.detail.id);
        }

        if (!selectedCategory) {
            categoryList();
            return;
        }

        state.setKey('page', 'ProductList');
    };


    const categoryList = () => {
        selectedCategory = null;
        selectedProduct = null;
        state.setKey('page', 'CategoryList');
    };
    const categoryAdd = () => {
        selectedCategory = null;
        selectedProduct = null;
        state.setKey('page', 'CategoryAdd');
    };
    const categoryEdit = ({detail}) => {
        selectedCategory = detail;
        selectedProduct = null;
        state.setKey('page', 'CategoryEdit');
    };

    const productAdd = ({detail}) => {
        selectedCategory = detail;
        selectedProduct = null;
        state.setKey('page', 'ProductAdd');
    };
    const productEdit = ({detail}) => {
        selectedProduct = detail;
        state.setKey('page', 'ProductEdit');
    };

    const init = async () => {
        state.setLoading(true);
        const [err, data] = await get('all');

        state.setLoading(false);
        if (err) {
            alert(err);
            return;
        }

        state.update(s => {
            s.page = 'CategoryList';
            s.categories = data.categories;
            s.products = data.products;
            // s.stores = data.stores;
            // s.chairs = data.chairs;
            return s;
        });
        state.fixCatsMap();

    };

    init();


    $: {
        page = $state.page;
        loading = $state.loading;
        selectedCategoryProducts = ($state.products && selectedCategory) ?
            $state.products.filter(p => p.food_category_id == selectedCategory.id) :
            [];
    }


</script>
<section class="content-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
{#if page == 'CategoryList'}

    <div class="panel panel-default">
        <div class="panel-heading">
            <h2>Κατηγορίες προϊόντων</h2>
            <div class="controls">
                <a href="#" on:click="{ categoryAdd }" title="Νέα κατηγορία"><i class="fa fa-plus"></i></a>
            </div>
        </div>
        <div class="panel-body">
            <table class="table table-list">
                <thead>
                    <tr>
                        <th>
                            Σειρά
                        </th>
                        <th>
                            Όνομα
                        </th>
                        <th>
                            Προϊόντα
                        </th>
                        <th>
                            Κατάσταση
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {#each $state.categories as category}
                        <CategoryListItem
                                {category}
                                catsmap="{ $state.catsMap }"
                                on:select="{ showCategoryProducts }"
                                on:add="{ productAdd }"
                                on:edit="{ categoryEdit }"
                                on:moveup="{ categoryActions.moveUp }"
                                on:movedown="{ categoryActions.moveDown }"
                        />
                    {:else}
                        <tr><td colspan="4">Δεν βρέθηκαν κατηγορίες</td></tr>
                    {/each}
                </tbody>
            </table>
        </div>
    </div>
{/if}

{#if page == 'CategoryAdd' || page == 'CategoryEdit' }
    <CategoryEditor
            category="{ selectedCategory }"
            on:save="{ categoryActions.save }"
            on:cancel="{ categoryList }"
    />
{/if}


{#if page == 'ProductList'}
    <header class="page-header">
        <h1>{ selectedCategory.title }</h1>
    </header>
    <div class="panel panel-default">
        <div class="panel-heading">
            <h2>Προϊόντα κατηγορίας</h2>
            <div class="controls">
                <a href="#" title="Προσθήκη προϊόντος" on:click="{ () => productAdd({detail:selectedCategory}) }"><i class="fa fa-plus"></i></a>
                <a href="#" class="control-close" title="Κλείσιμο" on:click="{ categoryList }"><i class="fa fa-times"></i></a>
            </div>
        </div>
        <div class="panel-body">
            <table class="table table-list">
                <thead>
                    <tr>
                        <th>
                            Σειρά
                        </th>
                        <th>
                            Όνομα
                        </th>
                        <th class="text-right">
                            Τιμή
                        </th>
                        <th>
                            Κατάσταση
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {#each selectedCategoryProducts as product}
                        <ProductListItem
                                {product}
                                on:edit="{ productEdit }"
                                on:add="{ productAdd }"
                                on:moveup="{ productActions.moveUp }"
                                on:movedown="{ productActions.moveDown }"
                        />
                    {:else}
                       <tr><td colspan="4">Δεν υπάρχουν προϊόντα</td></tr>
                    {/each}
                </tbody>
            </table>
        </div>
    </div>
{/if}

{#if page == 'ProductAdd' || page == 'ProductEdit' }
    <header class="page-header">
        <h1>{ selectedCategory.title }</h1>
    </header>
    <ProductEditor
            product="{ selectedProduct }"
            category="{ selectedCategory }"
            categories="{ $state.categories }"
            on:save="{ productActions.save }"
            on:delete="{ productActions.delete }"
            on:cancel="{ showCategoryProducts }"
    />
{/if}

{#if (loading)}
    <div class="overlay">
        <div>Loading...</div>
    </div>
{/if}
            </div>
        </div>
    </div>
</section>

<style>
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: rgba(0, 0, 0, .3);
        
    }

    .overlay div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
</style>

